<template>
  <app-container class="about">
    <h1>This is an about page</h1>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'

export default {
  name: 'About',
  components: { AppContainer }
}
</script>
